/* JS File for RockSolid Theme Campaign landing Page */
/*global $:false, jQuery:false*/
/*jslint browser: true*/
var equalHeight = (function($) {
    "use strict";

    // remove inline min height from the group
    function clear(group) {
        group.css('min-height', '');
    }

    // make group equal heights
    function equalHeight(group) {

        // reset height set by this script
        clear(group);

        // defining a variable that will keep track of the height of the tallest element
        var tallest = 0;

        // loop through elements, find the tallest outer height (includes padding)
        group.each(function () {

            // this is the outer height of the element (it doesn't round up or down to whole numbers)
            var thisHeight = this.getBoundingClientRect().bottom - this.getBoundingClientRect().top;

            if (thisHeight > tallest) {
                tallest = thisHeight;
            }
        });

        // loop through elements again, individually set their min-height so that their total height (including padding) = our tallest element
        group.each(function () {

            // if this has css box-sizing: border box, set the min-height equal to the tallest
            if (isBorderBox(this)) {

                group.css('min-height', Math.ceil(tallest));

            } else {

                // if an element has padding
                if ($(this).outerHeight() > $(this).height()) {

                    // calulate how much border and padding is on the element
                    var thisPadding = $(this).outerHeight() - $(this).height();

                    // set the height of the element to the tallest, but leave room for the padding
                    group.css('min-height', Math.ceil(tallest - thisPadding));

                } else {

                    // if the element has no padding, simply set the height to our tallest
                    group.css('min-height', Math.ceil(tallest));
                }
            }
        });
    }

    // check to see if the page is using box-sizing: border-box;
    function isBorderBox(elem) {
        return window.getComputedStyle(elem).boxSizing === "border-box";
    }

    return {
        equalHeight: equalHeight,
        outerHeight: equalHeight,
        clear: clear
    };

})(jQuery);

var ua = navigator.userAgent.toLowerCase();
var isAndroid = ua.indexOf("android") > -1;

function rockSolidWindowSize() {
    'use strict';
    var w = Math.max($(window).width(), window.innerWidth),
        type = '';
    
    if (w > 1700) {
        type = 'XL';
    } else if (w <=1699 && w >=1441) {
        type = 'Large';
    } else if (w <= 1440 && w >= 1280) {
        type = 'Large';
    } else if (w <= 1279 && w >= 1024) {
        type = 'Medium';
    } else if (w <= 1023 && w >= 440) {
        type = 'Small';
    } else {
        type = 'Default';
    }
    
    // change the css file of the tag with id="stl" and rel="stylesheet"
    $('body').removeClass('XL Large Medium Small Default').addClass(type);
}

function moveRockSolidElements() {
    "use strict";
    // MOVE CTA Panel 
    if ($('#RockSolid .cmsPageContent .Move-CTA-Here').length > 0 && ('#RockSolid .cmsPageContent .Move-CTA-Here #CallToAction').length === 0) {
        $('#RockSolid.Default #CallToAction').appendTo('#RockSolid .cmsPageContent .Move-CTA-Here');
        $('#RockSolid.Small #CallToAction').appendTo('#RockSolid .cmsPageContent .Move-CTA-Here');
    } else {
        $('#RockSolid.Default #CallToAction').insertAfter('.cmsPageContent > h1:first');
        $('#RockSolid.Small #CallToAction').insertAfter('.cmsPageContent > h1:first');
    }
    $('#RockSolid.Medium #CallToAction').insertAfter('#PageContent');
    $('#RockSolid.Large #CallToAction').insertAfter('#PageContent');
    $('#RockSolid.XL #CallToAction').insertAfter('#PageContent');
    
    // Calling focuspoint function
    if ($('#Banner').length) {
		$('.focuspoint img').css('width','auto');
	}
    // if ($('body.Default #Banner ').length) {
    //     $('.focuspoint').focusPoint();
    // }    
    // if ($('body.Small #Banner').length) {
    //     $('.focuspoint').focusPoint();
    // }    
}

// function equalHeight(group) {
//     "use strict";
//     var tallest = 0;
//     group.each(function () {
//         var thisHeight = $(this).height();
//         if (thisHeight > tallest) {
//             tallest = thisHeight;
//         }
//     });
//     group.css('height', tallest);
// }

// Change Tabs into Accordions
function tabsToAccordions() {
    'use strict';
    // do this for each tab container
    $('.TabContainer').each(function (k, TabContainer) {

        // new accordion container (jquery object)
        var newAccordion = $('<div class="ResponsiveAccordion Accordion ' + $(this).data('accordionClasses') + '"></div>');

        // loop through all tabs in the current tab container
        $(this).find('.Tab').each(function (k, tab) {

            // create objects for the new trigger and payload
            var accordionTrigger = $('<h3>' + $(tab).find(':header:first').text() + '</h3>'),
                accordionPayload = $('<div class="TheTabContent"></div>');

            // add trigger and empty payload to the accordion container
            accordionTrigger.appendTo(newAccordion);
            accordionPayload.appendTo(newAccordion);

            accordionTrigger.mousedown(function (e) {
                if (accordionPayload.is(':empty')) {
                    var newTabContents = $(tab).clone();

                    // if there's a google map in the tab, then do some funky stuff
                    if (newTabContents.find('.MapContainer').size() > 0) {
                        $(tab).find('#MapId').attr('id', 'MapIdOriginal');
                        $(tab).find('.MapContainer').attr('class', 'MapContainerOriginal');
                        newTabContents.find('#MapId').remove();
                        newTabContents.find('.GoogleLocateOnMapLink').remove();
                        setTimeout(function () { Geo_InitMap(); }, 1000);
                    }

                    // put tab contents in the payload
                    newTabContents.appendTo(accordionPayload);

                }
            }); // end click event

        });
        $(TabContainer).after(newAccordion);
    });
    
    doAccordions();
    
    // if you addded a 'StartOn' class to make an accordion payload automatically visible, trigger it so it has content
    $('.ResponsiveAccordion[class*="StartOn"]').each(function () {
        $(this).find('.Trigger').eq(parseInt($(this).attr('class').split('StartOn')[1]) - 1).trigger('mousedown');
    });
}

//main nav dropdowns
function MainNavDropDowns() {
    'use strict';
    if ($('body.Small').length) {
        $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown').removeClass('Open');
        $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown button').attr('aria-expanded','true');
        $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown ul').attr('aria-hidden','false');
    } else {
        $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown').removeClass('Open');
        $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown button').attr('aria-expanded','false');
        $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown ul').attr('aria-hidden','true');
    }
     if ($('body.Small').length) {
         //do nothing 
     } else {
        //hover functionality
        var hovertimer;
        var $li = $('.HeaderContent li.Menu .cmsPanelContent > ul > li.HasDropdown').hover(
            function () {
                var self = this;
                hovertimer = setTimeout(function(){
                    $(self).addClass('Open');
                    $(self).find('ul').attr('aria-hidden', 'false');
                    $(self).find('button').attr('aria-expanded','true');
                }, 300);
            },
            function () {
                clearTimeout(hovertimer);
                $li.removeClass('Open');
                $li.find('ul').attr('aria-hidden', 'true');
                $li.find('button').attr('aria-expanded','false');
            }
        );

        //click dropdown arrow button to activate dropdown menu
        $('.HeaderContent li.Menu .cmsPanelContent > ul > li.HasDropdown').on('click', 'button', function (e) {
            e.preventDefault();
            $(e.target).parent('li.HasDropdown').addClass('Open');
            $(e.target).parent('li.HasDropdown').find('ul').attr('aria-hidden', 'false');
            $(e.target).attr('aria-expanded', 'true');
        });
        
        $('.HeaderContent li.Menu .cmsPanelContent > ul > li').on('blur', '> ul > li:last-child', function(e) {
            e.preventDefault();
            $li.removeClass('Open');
            $li.find('ul').attr('aria-hidden', 'true');
            $li.find('button').attr('aria-expanded','false');
        });
    }
}

//bind to resize
$(window).resize(function() {
    'use strict';
    if ($('input').is(':focus')) {
        //do nothing
    } else {
        rockSolidWindowSize();
        moveRockSolidElements();
        MainNavDropDowns();
        
        $(".BackgroundPanelDark .cmspanel").css('height', 'auto');
        //make columns equal heigh
        equalHeight.equalHeight($('.BackgroundPanelDark .cmspanel'));
    
        if ($('body.Small').length) {
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown').removeClass('Open');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown button').attr('aria-expanded','true');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown ul').attr('aria-hidden','false');
        } else {
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown').removeClass('Open');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown button').attr('aria-expanded','false');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown ul').attr('aria-hidden','true');
        }
    }

    $('#Banner .BannerImg .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});

	if ($('#Banner').length) {
		$('.focuspoint img').css('width','auto');
	}
});

//bind to resize
$(window).on("orientationchange", function () {
    'use strict';
    if ($('input').is(':focus')) {
        //do nothing
    } else {
        rockSolidWindowSize();
        moveRockSolidElements();
        MainNavDropDowns();
        
        $(".BackgroundPanelDark .cmspanel").css('height', 'auto');

        //make columns equal heigh
        equalHeight.equalHeight($('.BackgroundPanelDark .cmspanel'));

        if ($('body.Small').length) {
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown').removeClass('Open');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown button').attr('aria-expanded','true');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown ul').attr('aria-hidden','false');
        } else {
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown').removeClass('Open');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown button').attr('aria-expanded','false');
            $('.HeaderContent li.Menu .cmsPanelContent > ul li.HasDropdown ul').attr('aria-hidden','true');
        }
    }    

    $('#Banner .BannerImg .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});

	if ($('#Banner').length) {
		$('.focuspoint img').css('width','auto');
	}
});

// Smooth Scrolling - from CSS Tricks Code Snippets
$(function() {
  "use strict";
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        // move focus to the element for keyboard users
        target.attr('tabindex', '0').focus();
        // remove focus on the element afterwards so you can't tab to this hidden element later
        target.attr('tabindex', '-1');
        return false;
      }
    }
  });
});

//slider functionality
function sliders() {
    $('.flexslider ul.slides').not('.slick-initialized').slick({
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 8000,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    });
}

// Doc Ready
$(document).ready(function () {
    'use strict';
    rockSolidWindowSize();
    sliders();
    moveRockSolidElements();
    
    $('body').removeClass('JavaScriptOff');
    
    var count = 1;
    //including classes to parent li and arrow element if drop down exists
    $('.HeaderContent li.Menu .cmsPanelContent > ul > li').each(function() {
        if ($(this).find('ul').length) {
            $(this).addClass('HasDropdown');
            $(this).find('a, > div.NonLink').attr('aria-haspopup','true');
            $(this).find('ul').attr( { 'aria-hidden':'true', 'aria-label':'submenu '+count, 'id':'submenu'+count });

            //add arrow button
            $(this).find('> a, > div.NonLink').after('<button aria-controls="submenu'+ count +'" aria-expanded="false" role="button"><span><span class="sr-only">Show submenu</span></span></button');
        }
        count++; 
    });
    MainNavDropDowns();

    // If page navigation does not exist - remove the CustomToggles div
    if ($.trim($('#SecondaryNavWrap').html()) === '') {
        //$('.CustomToggles').remove();
        $('#HeaderNavWrap').remove();
    } else {
        $('.HeaderContent').addClass('WithMenu');
        $('#Header').addClass('WithMenu');
    }
    
    // If page Banner does not exist - remove the BannerWrap
    if ($.trim($('#BannerWrap').html()) === '') {
        $('#BannerWrap').remove();
    }
    
    // add checkmarks to ul
    $('ul.CheckMarks li').each( function() {
        $(this).prepend('<span class="fa fa-check" />');    
    });
    
    $('#HeaderPanelWrap #HeaderPanel').each(function () {
        // Count the number of panels in each CampaignRow and adjust css accordingly
        var NumOfHeadPanels = $(this).find('.cmspanel').length;
        
        if (NumOfHeadPanels === 1) {
            $(this).find('.cmspanel').addClass('OnePanel');
        }
        if (NumOfHeadPanels === 2) {
            $(this).find('.cmspanel').addClass('TwoPanels');
        }
        if (NumOfHeadPanels === 3) {
            $(this).find('.cmspanel').addClass('ThreePanels');
        }
    });
    
    // Campaign Rows
    $('.CampaignRow').each(function () {

        // If a CampaignRow is empty remove it
        if ($.trim($(this).html()) === '') {
            $(this).remove();
        }

        // If a CampaignRow has a background image panel - apply the background
        var BackgroundImageDark = $(this).find('.cmspanel .BackgroundImageDark').html();
        var BackgroundImageLight = $(this).find('.cmspanel .BackgroundImageLight').html();
        var BackgroundImageSource = '';
        var BackgroundImageColor = '';
        
        if (typeof BackgroundImageDark !== 'undefined') {
            if ($(this).find('.cmspanel .BackgroundImageDark img').length) {
                BackgroundImageSource = $(this).find('.cmspanel .BackgroundImageDark img').attr('src');

                // add background image to CampaignRowWrap -- Using backstretch plugin 
                $(this).find('.cmspanel .BackgroundImageDark').closest('.CampaignRowWrap').addClass('BackgroundPanelDark').backstretch(BackgroundImageSource);

                // remove background image panel
                $(this).find('.cmspanel .BackgroundImageDark').closest('.cmspanel').remove();
            } else {
                BackgroundImageColor = $(this).find('.cmspanel .BackgroundImageDark p').text();
                $(this).find('.cmspanel .BackgroundImageDark').closest('.CampaignRowWrap').css('background',BackgroundImageColor);
                $(this).find('.cmspanel .BackgroundImageDark').closest('.cmspanel').remove();
            }
        }
        
        if (typeof BackgroundImageLight !== 'undefined') {
            if ($(this).find('.cmspanel .BackgroundImageLight img').length) {
                BackgroundImageSource = $(this).find('.cmspanel .BackgroundImageLight img').attr('src');

                // add background image to CampaignRowWrap -- Using backstretch plugin 
                $(this).find('.cmspanel .BackgroundImageLight').closest('.CampaignRowWrap').addClass('BackgroundPanelLight').backstretch(BackgroundImageSource);
    
                // remove background image panel
                $(this).find('.cmspanel .BackgroundImageLight').closest('.cmspanel').remove();
            } else {
                BackgroundImageColor = $(this).find('.cmspanel .BackgroundImageLight p').text();
                $(this).find('.cmspanel .BackgroundImageLight').closest('.CampaignRowWrap').css('background',BackgroundImageColor);
                // remove background image panel
                $(this).find('.cmspanel .BackgroundImageLight').closest('.cmspanel').remove();
            }
        }

        // If a CampaignRow has a Full Width Header panel - Treat differently
        var FullWidthHeader = $(this).find('.cmspanel .FullWidthHeader').html();

        if (typeof FullWidthHeader !== 'undefined') {
            //var BackgroundImageSource = $(this).find('.cmspanel .BackgroundImage img').attr('src');

            // add Headline to CampaignRow -- Using backstretch plugin 
            $(this).find('.cmspanel .FullWidthHeader').closest('.CampaignRow').prepend(FullWidthHeader);

            // remove background image panel
            $(this).find('.cmspanel .FullWidthHeader').closest('.cmspanel').remove();
        }
        
        // Count the number of panels in each CampaignRow and adjust css accordingly
        var NumOfPanels = $(this).find('.cmspanel').length;
        
        if (NumOfPanels === 1) {
            $(this).find('.cmspanel').addClass('OnePanel').wrapAll('<div class="OnePanelWrap" />');
        }
        if (NumOfPanels === 2) {
            $(this).find('.cmspanel').addClass('TwoPanels').wrapAll('<div class="TwoPanelsWrap" />');
        }
        if (NumOfPanels === 3) {
            $(this).find('.cmspanel').addClass('ThreePanels').wrapAll('<div class="ThreePanelsWrap" />');
        }
        if (NumOfPanels === 4) {
            $(this).find('.cmspanel').addClass('FourPanels').wrapAll('<div class="FourPanelsWrap" />');
        }
    });
    
    $(".BackgroundPanelDark .cmspanel").css('height', 'auto');

    //make columns equal height
    equalHeight.equalHeight($('.BackgroundPanelDark .cmspanel'));
    
    /* Mobile Navigation 
   ----------------------------------------------------------------- */
    $('.CustomToggles').each(function () {
        // create variables to use for the triggers and payloads
        var $CT = $(this),
            $triggers = $CT.find('.Triggers>li'),
            $payloads = $CT.find('.Payloads>li');

        // loop thru the triggers - using "i" as the counter, you can associate the tigger with the payload
        $triggers.each(function (i, trigger) {

            // clicking a trigger will slide up all payloads and will only slide down
            // if the associated payload wasn't the one you triggered
            $(trigger).click(function () {
                $triggers.removeClass('Active');
            
                if ($payloads.eq(i).is(':hidden')) {
                    $payloads.removeClass('Active');
                    // slide down the associated payload
                    // .slideDown(1000) is 1 second
                    // .slideDown(1000, function(){ do.something })
                    // do.something happens after the slideDown command is finished.
                    // zIndexTop is a class to make sure that the one you
                    // clicked on is always sliding over the others.
                    $payloads.eq(i).addClass('zIndexTop Active');
                    $payloads.eq(i).removeClass('zIndexTop');
                    $triggers.eq(i).addClass('Active');
                }
                else {
                    //$payloads.slideUp();
                    $payloads.removeClass('Active');
                    $triggers.removeClass('Active');
                }
            });
        });
    });
    

    // if ($('.flexslider').length) {
    //     $('.flexslider').flexslider({
    //         animation: "fade",
    //         slideshowSpeed: 8000,
    //         animationLoop: true,
    //         pauseOnHover: true,
    //         randomize: true
    //     });
    // }
    
    // Banner Focus Point on Interior Pages
    $('#Banner .BannerImg .focuspoint').focusPoint({
        reCalcOnWindowResize: true,
        throttleDuration: 100,
    });
    
    /* Add VideoContainer around iframed YouTube videos
    ---------------------------------------------------------------- */
    $('iframe[src*="youtube.com"], iframe[src*="youtube-nocookie.com"], iframe[src*="vimeo.com"]').each(function () {
        $(this).wrap('<div class="VideoContainer" />');
    });


	/*  Lightbox for Various Scenarios
    ----------------------------------------------------------------- */
	$("a.YouTubeVideo, a.VimeoVideo, a.ProviderVideo").modaal({ type: 'video' }); // .YouTubeVideo also adds the video icon overtop of the image inside this link
	$("a.Lightbox-for-img, a.FancyImage").modaal({ type: 'image' });
	$("a.Lightbox-for-video").modaal({ type: 'video' });
	$("a.Lightbox-for-iframe, a.Fancyiframe, a.FancyBox").modaal({ 
		type: 'iframe',
		width: 1000
	});

	// Don't open videos in lightbox on small devices
	if ($('a.FancyYouTube').length) { 
		if ($(window).width() > 620) {
			$("a.FancyYouTube").modaal({ type: 'video' });
		}
	}
    
    /* Nested Accordion fix */
    $('.Accordion .Accordion').removeClass('Accordion').removeClass('AccordionReady').addClass('NestedAccordion');
    $('.NestedAccordion>div').removeClass('Payload').addClass('Payload2').hide();
    $('.NestedAccordion>h1,.NestedAccordion>h2,.NestedAccordion>h3,.NestedAccordion>h4,.NestedAccordion>h5,.NestedAccordion>h6,.NestedAccordion>h7').removeClass('Trigger').addClass('Trigger2');
    $('Accordion').on('click', '.NestedAccordion .Trigger2', function () {
        if($(this).hasClass('Active')) {
            $(this).removeClass('Active').next('.Payload2').slideUp().removeClass('Open').css('display', 'none');
        } else {
            $(this).parent('.NestedAccordion').children('.Trigger2').removeClass('Active');
            $(this).addClass('Active').parent('.NestedAccordion').children('.Payload2').slideUp().removeClass('Open').css('display', 'none');
            $(this).next('.Payload2').slideDown().css('display', 'block').addClass('Open');
        }
    });
});

$(window).load(function() {
    //var myWindowWidth = Math.max($(window).width(), window.innerWidth);
    
    $('#GoogleMapCustomLegend').addClass('ClearFix');

    if ($('#ClientLocationsMap').length) {
        // Move Google Map Legend back
        setTimeout(function () {
            $('#GoogleMapCustomLegend').insertAfter('#ClientLocationsList');
        }, 1000);
    } 
    $('#Banner .BannerImg .focuspoint').focusPoint({
        reCalcOnWindowResize: true,
        throttleDuration: 100,
    });

    if ($('#Banner').length) {
        $('.focuspoint img').css('width','auto');
    }
});